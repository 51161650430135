<template>
  <auth-layout>
    <section class="text-center">
      <h1 class="text-2pxl lg:text-2xl font-mulish font-black uppercase">
        {{ APP_NAME }} ADMIN RESET PASSOWRD
      </h1>
      <h5 class="text-sm lg:text-base font-semibold text-oneGray">
        Enter email or phone to proceed
      </h5>
    </section>

    <form @submit.prevent="sendToken">
      <div>
        <label for="emailOrPhone">Username</label>
        <input
          id="emailOrPhone"
          type="text"
          class="input"
          v-model="form.emailOrPhone"
          placeholder="enter email or phone number"
        />
      </div>

      <div class="mt-7">
        <button
          type="submit"
          class="bg-idBlued text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
          :disabled="!emailOrPhoneReady || logging"
        >
          <spinner class="" v-if="logging"></spinner>
          <span>Proceed</span>
        </button>
      </div>
    </form>

    <section
      class="flex flex-col justify-center items-center mt-9 gap-5 text-xs lg:text-sm font-semibold"
    >
      <p class="">
        <router-link
          :to="{ name: 'Admin Login' }"
          class="underline text-blue-500"
        >
          Remembered password ?
        </router-link>
      </p>
    </section>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "ForgotPassword",

  components: {
    AuthLayout,
  },

  data() {
    return {
      form: {
        emailOrPhone: null,
      },
      logging: false,
    };
  },

  computed: {
    emailOrPhoneReady() {
      return (
        this.form.emailOrPhone &&
        (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.emailOrPhone) ||
          /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.emailOrPhone))
      );
    },
  },

  methods: {
    async sendToken() {
      try {
        if (!this.emailOrPhoneReady) {
          this.$swal({
            icon: "error",
            text: "Please enter your email and password",
          });
          return;
        }
        this.logging = true;

        const url = "/users/forgot-password";

        const payload = {
          email: this.form.emailOrPhone,
          baseUrl: `${window.location.origin}/admin/reset-password`,
        };

        const res = await this.$http.post(url, payload, {
          headers: { noauth: true },
        });

        this.logging = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.form.emailOrPhone = null;
        this.$router.push({ name: "Admin Login" });
      } catch (error) {
        this.logging = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-14;
  max-width: 35rem;
}
</style>
